import Vue from 'vue';
import App from './App.vue';
import router from './router';
Vue.prototype.Vue = Vue;

import "vant/lib/index.css";
import Vant from 'vant';
Vue.use(Vant)

import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui'; 
Vue.use(ElementUI);

import { backPrePage, confirmTips, initMap } from "@/utils/util";
Vue.prototype.$backPrePage = backPrePage;
Vue.prototype.$confirmTips = confirmTips;
Vue.prototype.$initMap = initMap;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
