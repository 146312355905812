import request from '@/utils/request';


//根据手表获取最新地图
export function findOneLastwatchPosition() {
    return request({
        url: `/api/watchPosition/findOneByWatchSerial/${sessionStorage.getItem("deviceSerial")}`,
        method: 'GET'
    })
}
//根据学生id查看多台可用手表
export function findListwatchStudent() {
    return request({
        url: `/api/watchStudent/findList/${sessionStorage.getItem("studentId")}`,
        method: 'GET'
    })
}
// 绑定
export function bind(data) {
    return request({
        url: `/api/watchStudent/bind`,
        method: 'POST',
        data
    })
}
//解绑
export function deletewatchFamily(studentId) {
    return request({
        url: `/api/watchStudent/unBind`,
        method: 'POST',
        data:{
            deviceSerial:sessionStorage.getItem("deviceSerial"),
            studentId:studentId
        }
    })
}


//电话号码本列表
export function findListwatchPhoneBook() {
    return request({
        url: `/api/watchPhoneBook/findList`,
        method: 'GET'
    })
}
//删除电话号码
export function deletewatchPhoneBook(id) {
    return request({
        url: `/api/watchPhoneBook/delete/`+id,
        method: 'GET'
    })
}
//修改电话号码
export function updateWatchwatchPhoneBook(data) {
    return request({
        url: `/api/watchPhoneBook/updateWatch`,
        method: 'POST',
        data
    })
}
//添加电话号码
export function addWatchwatchPhoneBook(data) {
    return request({
        url: `/api/watchPhoneBook/addWatch`,
        method: 'POST',
        data
    })
}
//电子围栏列表
export function findListwatchGeoFence() {
    return request({
        url: `/api/watchGeoFence/findList`,
        method: 'GET'
    })
}
//添加安全区域
export function addWGeoFenceh(data) {
    return request({
        url: `/api/watchGeoFence/addWGeoFenceh`,
        method: 'POST',
        data
    })
}
//禁用启用安全区域
export function updateStatuswatchGeoFence(id,status) {
    return request({
        url: `/api/watchGeoFence/updateStatus/`+id+'/'+status,
        method: 'GET',
    })
}
//删除安全区域
export function deletewatchGeoFence(id) {
    return request({
        url: `/api/watchGeoFence/delete/`+id,
        method: 'GET',
    })
}
//查看已设置时间列表
export function findListCourseTime() {
    return request({
        url: `/api/watchCourseTime/findListCourseTime`,
        method: 'GET'
    })
}
//修改状态 禁用启用
export function updateStatuswatchCourseTime(id,status) {
    return request({
        url: `/api/watchCourseTime/updateStatus/`+id+'/'+status,
        method: 'GET'
    })
}
//新增或者修改时间
export function savewatchCourseTime(data) {
    return request({
        url: `/api/watchCourseTime/save`,
        method: 'POST',
        data
    })
}
// 通用设置
export function commeSet(operateCode	) {
    return request({
        url: `/api/watchSet/watchCommonSet/${operateCode}`,
        method: 'GET'
    })
}
//查看已设置闹钟列表
export function findListClockTime() {
    return request({
        url: `/api/watchSet/getWatchRemind`,
        method: 'GET'
    })
}
//新增或者修改时间
export function updateClockTime(data) {
    return request({
        url: `/api/watchSet/setWatchRemin`,
        method: 'POST',
        data
    })
}
//SOS
export function getSOSInfo(data) {
    return request({
        url: `/api/watchSet/getWatchSOS`,
        method: 'GET',
        data
    })
}
export function setSOSInfo(data) {
    return request({
        url: `/api/watchSet/setWatchSOS`,
        method: 'POST',
        data
    })
}
//app列表查询
export function getAppInfo() {
    return request({
        url: `/api/platformAppManage/page`,
        method: 'POST',
        data: {
            pageNum: 1,
            pageSize: 10,
            deviceSerial: sessionStorage.getItem("deviceSerial")
        }
    })
}
//app审批
export function approveApp(applyId) {
    return request({
        url: `/api/appInstallManage/approve`,
        method: 'POST',
        data: {
            applyId,
            status: 1
        }
    })
}
// 定时开关机列表
export function getTimeSwitch() {
    return request({
        url: `/api/watchSet/getWatchSPOF`,
        method: 'GET',
    })
}
// 定时开关机编辑
export function editTimeSwitch(data) {
    return request({
        url: `/api/watchSet/setWatchSPOF`,
        method: 'POST',
        data
    })
}
//根据手表获取管理员
export function getManager() {
    return request({
        url: `/api/watchFamily/findList/${sessionStorage.getItem("deviceSerial")}`,
        method: 'GET'
    })
}
//操作管理员
export function operateManager(id,status) {
    return request({
        url: `/api/watchFamily/operate/${id}/${status}`,
        method: 'GET',
        headers: {
            userId: sessionStorage.getItem("studentId")
        }
    })
}
//删除管理员
export function delManager(id) {
    return request({
        url: `/api/watchFamily/delete/${id}`,
        method: 'GET'
    })
}
//获得控制开关
export function getControlSwitch() {
    return request({
        url: `/api/watchSet/getWatchAPPLOCK`,
        method: 'GET'
    })
}
//获得控制开关
export function setControlSwitch(data) {
    return request({
        url: `/api/watchSet/setWatchAPPLOCK`,
        method: 'POST',
        data
    })
}
