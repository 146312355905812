<template>
    <van-nav-bar :fixed="true" 
        :left-arrow="leftArrow" 
        :left-text="leftText" 
        :title="title" 
        :right-text="rightText" 
        @click-left="onClickLeft"
        @click-right="onClickRight"
    />
</template>
<script>
    export default {
        props: {
            leftArrow: {
                type: Boolean,
                default: true
            },
            leftText: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: ''
            },
            rightText: {
                type: String,
                default: ''
            },
        },
        methods: {
            onClickLeft() {
                if(this.leftArrow) {
                    this.$backPrePage();
                }else {
                    this.$emit('clickLeft');
                }
            },
            onClickRight() {
                this.$emit('clickRight');
            },
        }
    }
</script>
<style scoped>
    .van-nav-bar--fixed {
        z-index: 9999;
    }
</style>