import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/home/index.vue';
import Bind from '@/views/bind/index.vue';

Vue.use(VueRouter)
const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/',
    name: 'bind',
    component: Bind
  },
  {
    path: '/watch/tpcall',
    name: 'bind',
    component: Bind
  },
  {
    path: '/watchList',
    name: 'watchList',
    component: () => import('@/views/bind/watchList.vue')
  },
  { 
    path: '/scanCode', 
    name: 'scanCode',
    component: () => import('@/views/bind/scanCode.vue')
  },
  {
    path: '/phonebook',
    name: 'phonebook',
    component: () => import('@/views/Phonebook/index.vue')
  },
  {
    path: '/phoneEdit',
    name: 'phoneEdit',
    component: () => import('@/views/Phonebook/phoneEdit.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/map/index.vue')
  },

  {
    path: '/area',
    name: 'area',
    component: () => import('@/views/map/area.vue')
  },
  {
    path: '/addarea',
    name: 'addarea',
    component: () => import('@/views/map/addArea.vue')
  },
  {
    path: '/timelist',
    name: 'timelist',
    component: () => import('@/views/attendclass/list.vue')
  },
  {
    path: '/edittime',
    name: 'edittime',
    component: () => import('@/views/attendclass/edit.vue')
  },
  {
    path: '/schooltime',
    name: 'schooltime',
    component: () => import('@/views/attendclass/school.vue')
  },
  {
    path: '/appList',
    name: 'appList',
    component: () => import('@/views/appManager/list.vue')
  },
  {
    path: '/appEdit',
    name: 'appEdit',
    component: () => import('@/views/appManager/edit.vue')
  },
  {
    path: '/timeswitch',
    name: 'timeswitch',
    component: () => import('@/views/timeswitch/index.vue')
  },
  {
    path: '/clocklist',
    name: 'clocklist',
    component: () => import('@/views/clock/list.vue')
  },
  {
    path: '/editclock',
    name: 'editclock',
    component: () => import('@/views/clock/edit.vue')
  },
  {
    path: '/sos',
    name: 'sos',
    component: () => import('@/views/sos/list.vue')
  },
  {
    path: '/manager',
    name: 'manager',
    component: () => import('@/views/manager/list.vue')
  },
  {
    path: '/controlSwitch',
    name: 'controlSwitch',
    component: () => import('@/views/controlSwitch/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
