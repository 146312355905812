<template>
  <div class="comm-box">
    <headBar :leftArrow="false" :leftText="watchMsg.studentName" rightText="+" @clickLeft="getWatchList" @clickRight="addWatch" />
    <div id="map"></div>
    <!-- 地图信息 -->
    <div class="comm-card card1">
      <div class="left">
        <img :src="require('@/static/home/dinwei.png')" />
        <div @click="pageJump({pathName: 'map'})">
          <div class="up comm-text-hide">{{ watchMsg.address }}</div>
          <div class="down">
            <div>{{ watchMsg.dateTime }}</div>
            <span>
              <img v-if="watchMsg.electric == 0" :src="require('@/static/home/0.png')" />
              <img v-if="watchMsg.electric > 0 && watchMsg.electric < 26" :src="require('@/static/home/0-25.png')" />
              <img v-if="watchMsg.electric > 25 && watchMsg.electric < 46" :src="require('@/static/home/26-45.png')" />
              <img v-if="watchMsg.electric > 45 && watchMsg.electric < 71" :src="require('@/static/home/46-70.png')" />
              <img v-if="watchMsg.electric > 70 && watchMsg.electric < 100" :src="require('@/static/home/71-99.png')" />
              <img v-if="watchMsg.electric == 100" :src="require('@/static/home/100.png')" />
              {{ watchMsg.electric }}%
            </span>
            <div v-if="watchMsg.networkType == 0 ">GPS定位</div>
            <div v-if="watchMsg.networkType == 1 ">WIFI定位</div>
            <div v-if="watchMsg.networkType == 2 ">基站定位</div>
          </div>
        </div>
      </div>
      <div class="right" @click="reMap">
        <img :src="require('@/static/home/shauxin.png')" />
        <div>刷新</div>
      </div>
    </div>
    <!-- 常用功能 -->
    <div class="comm-card">
      <van-grid>
        <van-grid-item v-for="(item, index) in commonFun" :key="index" @click="pageJump(item)">
          <van-icon :name="item.icon" :size="58" />
          <span class="common-label">{{ item.label }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 特有功能 -->
    <div class="comm-card" hidden>
      <div class="card-title">特有功能</div>
      <van-grid>
        <van-grid-item v-for="(item, index) in basicFun" :key="index" :icon="item.icon" :text="item.label" @click="pageJump(item)" />
      </van-grid>
    </div>
    <!-- 基础功能 -->
    <div class="comm-card">
      <div class="card-title">基础功能</div>
      <van-grid>
        <van-grid-item v-for="(item, index) in uniqueFun" :key="index" :icon="item.icon" :text="item.label" @click="pageJump(item)" />
      </van-grid>
    </div>
    <div class="btn comm-btn" @click="unBind">解除绑定</div>
  </div>
</template>

<script>
  import { deletewatchFamily, findOneLastwatchPosition, commeSet } from "@/utils/api";
  import headBar from '@/components/headBar.vue';
    export default {
        components:{
            headBar
        },
    data() {
      return {
        map:null,
        watchMsg: {},
        commonFun: [
          {
            icon: require('@/static/home/dianhuaben.png'),
            label: '电话本',
            pathName: 'phonebook'
          },
          {
            icon: require('@/static/home/dianhua.png'),
            label: '电话',
            pathName: ''
          },
          {
            icon: require('@/static/home/jinyong.png'),
            label: '上课禁用',
            pathName: 'timelist'
          },
          // {
          //   icon: require('@/static/home/zhifu.png'),
          //   label: '宝贝支付',
          //   pathName: ''
          // }
          {
            icon: require('@/static/home/shdian.png'),
            label: '应用商店',
            pathName: 'appList'
          },
        ],
        basicFun: [
          {
            icon: require('@/static/home/shijian.png'),
            label: '屏幕时间管理',
            pathName: ''
          },
          {
            icon: require('@/static/home/jiankang.png'),
            label: '健康',
            pathName: ''
          },
          {
            icon: require('@/static/home/xinzhuang.png'),
            label: '课程表',
            pathName: ''
          },
          {
            icon: require('@/static/home/xiangce.png'),
            label: '手表相册',
            pathName: ''
          },
          {
            icon: require('@/static/home/shdian.png'),
            label: '应用商店',
            pathName: 'appList'
          },
          {
            icon: require('@/static/home/shichang.png'),
            label: '应用使用时长',
            pathName: ''
          },
          {
            icon: require('@/static/home/linsheng.png'),
            label: '设备铃声',
            pathName: ''
          }
        ],
        uniqueFun: [
          {
            icon: require('@/static/home/jiating.png'),
            label: '家庭成员',
            pathName: 'manager'
          },
          // {
          //   icon: require('@/static/home/xiaoxi.png'),
          //   label: '设备消息',
          //   pathName: ''
          // },
          // {
          //   icon: require('@/static/home/sbxx.png'),
          //   label: '代收手表信息',
          //   pathName: ''
          // },
          {
            icon: require('@/static/home/zsb.png'),
            label: '找设备',
            setParams: 9
          },
          // {
          //   icon: require('@/static/home/jjld.png'),
          //   label: '拒接陌生来电',
          //   pathName: ''
          // },
          // {
          //   icon: require('@/static/home/sdms.png'),
          //   label: '省电模式',
          //   pathName: ''
          // },
          {
            icon: require('@/static/home/kgsz.png'),
            label: '开关设置',
            pathName: 'controlSwitch'
          },
          // {
          //   icon: require('@/static/home/wifi.png'),
          //   label: 'WiFi设置',
          //   pathName: ''
          // },
          // {
          //   icon: require('@/static/home/axjl.png'),
          //   label: '爱心奖励',
          //   pathName: ''
          // },
          {
            icon: require('@/static/home/dskg.png'),
            label: '定时开关机',
            pathName: 'timeswitch'
          },
          {
            icon: require('@/static/home/sznz.png'),
            label: '设备闹钟',
            pathName: 'clocklist'
          },
          // {
          //   icon: require('@/static/home/dxtx.png'),
          //   label: '短信提醒',
          //   pathName: ''
          // },
          // {
          //   icon: require('@/static/home/gzms.png'),
          //   label: '工作模式',
          //   pathName: ''
          // },
          {
            icon: require('@/static/home/czsb.png'),
            label: '重置设备',
            setParams: 12
          },
          {
            icon: require('@/static/home/yccq.png'),
            label: '远程重启',
            setParams: 8
          },
          {
            icon: require('@/static/home/ycgj.png'),
            label: '远程关机',
            setParams: 7
          },
          {
            icon: require('@/static/home/sos.png'),
            label: 'SOS/亲情号码',
            pathName: 'sos'
          },
          // {
          //   icon: require('@/static/home/sbxx2.png'),
          //   label: '设备信息',
          //   pathName: ''
          // }
        ],
      };
    },
    mounted() {
      this.getwatchMsg();
    },
    methods: {
      pageJump(item) {
        if(item.setParams) {
          this.$confirmTips(`${item.label}？`).then(() => {
            commeSet(item.setParams).then(res => {
              if (res.data.code == 0) {
                this.$toast(`${item.label}成功`);
              }
            });
          })
        }else {
          if(item.pathName) {
            this.$router.push({
              name: item.pathName
            });
          }else {
  
          }
        }
      },
      //获取手表信息
      getwatchMsg() {
        findOneLastwatchPosition().then(res => {
          if(res.data.code == 0) {
            this.watchMsg = res.data.data;
            if(this.watchMsg.status == 0 && !sessionStorage.getItem('isFresh') ) {
              this.$toast('请手动刷新地图');
              sessionStorage.setItem('isFresh', true)
            }
            setTimeout(() => {
              this.map = this.$initMap(this.watchMsg);
            }, 500);
          }
        })
      },
      //获取手表列表
      getWatchList() {
        this.$router.push("/watchList");
      },
      //绑定另一个手表
      addWatch() {
        let token = sessionStorage.getItem("token");
        let studentId = sessionStorage.getItem("studentId");
        let deviceSerial = sessionStorage.getItem("deviceSerial");
        this.$router.push(`/?token=${token}&id=${studentId}&deviceSerial=${deviceSerial}&type=0`);
      },
      //解绑
      unBind() {
        let studentId = sessionStorage.getItem("studentId");
        this.$confirmTips('解绑该设备？').then(() => {
          deletewatchFamily(studentId).then(res => {
            if (res.data.code == 0) {
              if(res.data.data.length) {
                this.$router.push("/watchList");
              }else {
                let token = sessionStorage.getItem("token");
                let studentId = sessionStorage.getItem("studentId");
                this.$router.push(`/?token=${token}&id=${studentId}`);
              }
            }
          });
        })
      },
      reMap() {
        findOneLastwatchPosition().then(res => {
          if(res.data.code == 0) {
            this.watchMsg = res.data.data;
            if(this.watchMsg.status == 0) {
              this.$toast(this.watchMsg.message);
            }
            setTimeout(() => {
              let point = new BMapGL.Point(this.watchMsg.longitudeValue, this.watchMsg.latitudeValue);
              this.map.setCenter(point);
              this.map.clearOverlays();
              this.map.addOverlay(new BMapGL.Marker(point));
            }, 500);
          }
        })
      }
    },
    destroyed() {
      if(this.map) {
        this.map.destroy();
      }
    }
  };
</script>

<style scoped>
  #map {
    width: 100%;
    height: 234px;
    z-index: -1;
  }
  .card1 {
    margin-top: -20px;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    display: flex;
  }
  .card1 .left {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .card1 .left img {
    width: 30px;
    height: 30px;
  }
  .card1 .left .up {
    width: 230px;
    color: #111111;
    font-size: 12px;
  }
  .card1 .left .down {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card1 .left .down div {
    color: #999999;
    font-size: 12px;
  }
  .card1 .left .down img {
    width: 19px;
    height: 15px;
  }
  .card1 .left .down span {
    font-size: 11px;
    color: #1296db;
    display: flex;
    align-items: center;
  }
  .card1 .right {
    margin-left: 20px;
    width: 50px;
    text-align: center;
  }
  .card1 .right img {
    width: 20px;
    height: 20px;
  }
  .card1 .right div {
    font-size: 10px;
    font-weight: 400;
    color: #666666;
  }
  .card-title {
    color: #111111;
    font-size: 15px;
    font-weight: bold;
    margin-top: 12px;
    margin-left: 12px;
  }
  :deep(.van-grid-item__content) {
    padding: 16px 0px;
  }
  :deep([class*=van-hairline]::after) {
    border: none;
  }
  .common-label {
    font-size: 14px;
    color: #333333;
  }
  :deep(.van-grid-item__text) {
    color: #333333;
  }
  .btn {
    background-color: #ffffff;
    margin: 12px auto;
  }
</style>