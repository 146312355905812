<template>
    <div class="comm-box">
        <headBar title="填写设备信息"></headBar>
        <img :src="require('@/static/binding/sbewm.png')" />
        <div class="tips">请扫描设备码</div>
        <van-field class="inputStyle" v-model="bangdinlist.deviceSerial" placeholder="输入/扫码注册码" />
        <div class="comm-title">基本信息</div>
        <van-field class="inputStyle h-55" v-model="bangdinlist.nickName" placeholder="设备昵称(请避免使用真实姓名)" />
        <van-field class="inputStyle h-55" v-model="bangdinlist.relationship" placeholder="请填写关系" />
        <div class="btn comm-btn" @click="bindWatch">绑定</div>
    </div>
</template>
  
<script>
    import { bind } from '@/utils/api';
    import headBar from '@/components/headBar.vue';
    export default {
        components:{
            headBar
        },
        data() {
            return {
                bangdinlist: {
                    deviceSerial: "",
                    nickName: "",
                    relationship: "",
                    studentId: null,
                },
            }
        },
        created() {
            const queryParams = new URLSearchParams(window.location.search);
            sessionStorage.setItem("token", queryParams.get("token"));
            sessionStorage.setItem("studentId", queryParams.get("id"));
            sessionStorage.setItem("deviceSerial", queryParams.get("deviceSerial"));
            if (queryParams.get("type") == 1) {
                this.$router.push("/home");
            }
            if (sessionStorage.getItem("setScanResult")) {
                this.bangdinlist.deviceSerial = sessionStorage.getItem("setScanResult")
                sessionStorage.removeItem("setScanResult")
            }
        },
        methods: {
            bindWatch() {
                this.bangdinlist.studentId = sessionStorage.getItem("studentId")
                bind(this.bangdinlist).then(res => {
                    if (res.data.code == 0) {
                        sessionStorage.setItem('deviceSerial',this.bangdinlist.deviceSerial)
                        this.$router.push('/home');
                    }
                })
            },
            scanCode() {
                this.$router.push({
                    path: '/scanCode'
                });
            }
        }
    }
</script>
  
<style scoped>
    .comm-box > img {
        display: block;
        width: 159px;
        height: 159px;
        margin: 18px auto;
    }
    .tips {
        color: #A8A8A8;
        font-size: 15px;
        text-align: center;
    }
    .inputStyle {
        width: 345px;
        border-radius: 5px;
        margin: 10px auto 0px;
    }
    .h-55 {
        height: 55px;
    }
    .btn {
        margin-top: 43px;
    }
</style>