import router from '@/router';

export const backPrePage = () => {
    router.back();
}
import { Dialog } from 'vant';
export const confirmTips = (message='', title="提示", confirmButtonText="确定", cancelButtonText="取消") => {
    return new Promise((resolve, reject) => {
        Dialog.confirm({
            title,
            message: '确认' + message,
            confirmButtonText,
            cancelButtonText
          }).then(() => resolve()).catch(() => reject);
    })
}

export const initMap = (mapObj) => {
    let map = new BMapGL.Map("map");
    let point = new BMapGL.Point(mapObj.longitudeValue, mapObj.latitudeValue);
    map.centerAndZoom(point, 16);
    map.addOverlay(new BMapGL.Marker(point));
    map.enableScrollWheelZoom(true); 
    return map;
}