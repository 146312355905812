import axios from 'axios';
import { baseURL } from '@/utils/configInfo';
import { Toast } from 'vant';

const loading = {
    loadingInstance: null,
    open: function () {
        if(this.loadingInstance === null) {
            // this.loadingInstance = Toast.loading({
            //     duration: 0,
            //     message: '加载中...',
            //     forbidClick: true,
            //     loadingType: 'spinner'
            // })
        }
    },
    close: function () {
        if(this.loadingInstance !== null) {
            this.loadingInstance.clear()
        }
        this.loadingInstance = null
    }
}

const request = axios.create({
    baseURL,
    timeout: 5000
})
request.interceptors.request.use(config => {
    loading.open()
    config.headers['Authorization'] = sessionStorage.getItem('token');
    let deviceSerial = sessionStorage.getItem("deviceSerial");
    if(deviceSerial) {
        config.headers['deviceSerial'] = deviceSerial;
    }
    return config
}, error => {
    loading.close()
    return Promise.reject(error);
})

request.interceptors.response.use(response =>{
    loading.close()
    const resp = response.data
    if(resp.code != 0) {
        Toast.fail(resp.msg || '系统异常');
    }
    return response
}, error => {
    loading.close()
    Toast.fail(error.msg);
    return Promise.reject(error);
})
export default request